import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ziyara from "../../Assets/Projects/ziyara.png";
import onepath from "../../Assets/Projects/onepath.png";
import ig from "../../Assets/Projects/ig.png";
import trucktracking from "../../Assets/Projects/trucktracking.png";
import icheck from "../../Assets/Projects/icheck.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ziyara}
              isWeb={false}
              isIos={true}
              title="Ziyara"
              description="Ziyara is changing the way you manage your contacts. With features like customizable folders, shared information, and adjustable access levels, this app lets you organize your personal and professional connections effortlessly. You can explore individual contact profiles, jot down notes, and keep up with recent activities. Perfect for staying on top of networking follow-ups or staying connected with old friends, Ziyara keeps you organized and in the loop.
              Behind the scenes, the project's backend is powered by PHP, utilizing the CodeIgniter framework. For a seamless mobile experience, both the iOS and Android apps are crafted with React Native."
              demoLink="https://play.google.com/store/apps/details?id=com.ziyara"
              iosLink="https://apps.apple.com/au/app/ziyara-contact-management/id1636070367"
            />
          </Col>
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={onepath}
              isWeb={true}
              isIos={false}
              title="OnePath Migration"
              description="Meet One Path Migration, your trusted partner for seamless immigration services, guiding you towards your dreams of studying, working, or living abroad. This website, crafted with Strapi CMS for efficient content management and Next.js for a dynamic frontend, is designed to make your migration journey as smooth as possible."
              demoLink="https://onepathmigration.com/"
            />
          </Col>
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={ig}
              isWeb={true}
              isIos={false}
              title="Investors Group Newsletter System"
              description="This project is built with the robust Laravel 10 and powered by PHP 8. This newsletter system, designed for organizational needs, offers seamless management of mailing lists and streamlined newsletter distribution. With an intuitive TinyMCE WYSIWYG editor, crafting engaging email content is a breeze.
              Integrated a QR to Contact feature, allowing you to generate QR codes directly from the contact form. Simplify your communication processes with this powerful and versatile solution."
              demoLink="https://investorsgroup.me/"
            />
          </Col>
          <Col md={4} className="project-card">

            <ProjectCard
              imgPath={trucktracking}
              isWeb={true}
              isIos={false}
              title="Napco Truck Tracking"
              description="Built with Dotnet C#, this project is a truck tracking system tailored for Napco. It serves as a vital tool for monitoring delivery trucks, sending prompt notifications to drivers for extended stops or route deviations.
              I contributed enhancements to this project, focusing on feature additions and upgrading the system from Dotnet 3 to 6. The frontend, powered by Vue.js, received thoughtful improvements. These efforts aimed to elevate the functionality and performance of the truck tracking system for Napco."
              demoLink="https://tracking.napconational.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={icheck}
              isWeb={true}
              isIos={false}
              title="Napco iCheck"
              description="Constructed with Dotnet and Vue.js, this audit system for Napco streamlines the entire auditing process, encompassing creation, filling, and management of audit reports across various Napco companies. My role involved bug fixing and introducing new features like the workload management feature, specifically designed for both auditors and auditees"
              demoLink="https://icheckglobal.azurewebsites.net/"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
